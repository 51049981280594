export const oneModule = {
    module: "",
    moduleWeight: "",
    assignments: [
        {
            name: "",
            grade: "",
            weight: "",
        },
    ],
    moduleMark: "",
};
export const initial = {
    modules: [oneModule],
};
